import React from "react";
import {useModal} from "../context";
import Feedback from "./modals/Feedback";
function Footer() {
    const { show, hide} = useModal();
    return (
        <>
            <div className="footer">
                <div className="container">
                    <div className="footer__nav">
                        <ul>
                            <li>
                                <a href="https://www.mars.com/privacy-policy-russian" target="_blank">Конфиденциальность</a>
                            </li>
                            <li>
                                <a href="https://www.mars.com/legal-russia" target="_blank">Юридические условия</a>
                            </li>
                            <li>
                                <a href="https://mars.com/" target="_blank">Владелец сайта</a>
                            </li>
                            <li>
                                <a onClick={()=>{
                                    show(<Feedback product={true}/>, "modal--form modal--feedback");
                                }}>Задать вопрос о продукте</a>
                            </li>
                            <li>
                                <a href="/user-agreement.pdf" target="_blank">Пользовательское соглашение</a>
                            </li>
                            <li>
                                <a href="https://www.mars.com/cookies" target="_blank">Cookies</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__rights">
                        © 2024 Mars, Incorporated. Все права защищены
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
