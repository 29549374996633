import { Routes, Route } from 'react-router-dom'
import React from 'react';
import {ModalProvider} from "./context";
import Header from './components/Header'
import Footer from './components/Footer'
import Constructor from './pages/Constructor'
import {Helmet} from "react-helmet";

function App() {
  return (
      <>
          <Helmet>
              <title>Дарите воспоминания</title>
              <meta property="og:title" content="Дарите воспоминания"/>
          </Helmet>
          <ModalProvider>
              <div id="wrapper" className="wrapper">
                  <Header/>
                  <Routes>
                      <Route path="/" element={<Constructor />} />
                  </Routes>
                  <Footer/>
              </div>
          </ModalProvider>
      </>
  );
}

export default App;
