import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import ReactPlayer from 'react-player'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import axiosInstance from "../helpers/axios";

function Constructor(){
    const [dictionary, setDictionary] = useState({
        firstNames: [],
        lastNames: [],
        texts: []
    });
    const [name, setName] = useState('');
    const [videoLink, setVideoLink] = useState('');
    const [fname, setFname] = useState('');
    const [data, setData] = useState({
        name: null,
        name_id: null,
        name_key: null,
        fname: null,
        fname_id: null,
        fname_key: null,
        text: null,
        text_id: null,
        text_key: null
    });

    const [fixed, setFixed] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [mode, setMode] = useState(0);
    const [step, setStep] = useState(0);
    const [appeal, setAppeal] = useState(0);
    const [text, setText] = useState(0);
    const [copied, setCopied] = useState(false);
    const [isVideoLoading, setIsVideoLoading] = useState(false);
    const [resultError, setResultError] = useState('');
    const [code, setCode] = useState('');
    const [formCode, setFormCode] = useState('');
    const [play, setPlay] = useState(false);
    const [playDemo, setPlayDemo] = useState(false);

    const [paused, setPaused] = useState(false);
    const [playing, setPlaying] = useState(true);
    const [pressBtn, setPressBtn] = useState(false);
    const [ended, setEnded] = useState(false);
    const [preview, setPreview] = useState(false);
    const [result, setResult] = useState(false);
    const [processing, setProcessing] = useState(false);

    useEffect(()=>{
        getDictionary();
    }, []);

    useEffect(() => {
        const handleScroll = event => {
            const el = document.getElementById('cnst-bl');
            console.log(el);

            console.log(el.offsetHeight + ' ' + window.outerHeight);

            if(window.scrollY > (el.offsetHeight - window.outerHeight)){
                setFixed(true);
            }else{
                setFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(()=>{
        if(ended){
            const el = document.getElementById('preview-player');
            if(el){
                el.scrollTo({ top: 300, behavior: 'smooth' });
            }
        }
    }, [ended]);

    const reset = () => {
        setStep(0);
        setMode(0);
        setFormCode('');
        setResult(false);
        setPaused(false);
        setPreview(false);
        setEnded(false);
        setPlay(false);

        setData({
            name: null,
            name_id: null,
            name_key: null,
            fname: null,
            fname_id: null,
            fname_key: null,
            text_id: null,
            text_key: null
        });
        setName('');
        setFname('');
        setVideoLink('');
    }

    const completeCongratulations = async() => {
        setProcessing(true);
        const formData = new FormData();

        formData.append("first_name_id", data.name_id);
        formData.append("last_name_id", data.fname_id);
        formData.append("text_id", data.text_id);


        await fetch(process.env.REACT_APP_API_PROXY + '/congratulations/api/send', {
            method: "POST",
            body: formData,
        }).then((response) => response.json()).then((response) => {
            setTimeout(()=>{
                if(response.code){
                    setPreview(true);
                    setProcessing(false);
                    setCode(response.code);
                    setVideoLink(response.link);
                }else{
                    setProcessing(false);
                }
            }, 1500);
        });
    }

    const getCongratulation = async() => {
        setIsSubmitting(true);
        window.dataLayer.push({
            'event': 'view_video'
        });
        try {
            const response = await axiosInstance.get('/congratulations/api/get/' + formCode);
            if(response.data){
                setIsSubmitting(false);
                setData({
                    name: response.data.first_name,
                    name_id: response.data.first_name_id,
                    name_key: response.data.first_name_key,
                    fname: response.data.last_name,
                    fname_id: response.data.last_name_id,
                    fname_key: response.data.last_name_key,
                    text_id: response.data.text_id,
                    text_key: response.data.text_key
                });

                setVideoLink(response.data.link);

                setPlay(true);
                setResultError('');
            }
        } catch (error) {
            if(error.response.status === 404){
                setResultError('Неверный код');
            }
            setIsSubmitting(false);
        }
    }

    const getDictionary = async() => {
        await fetch(process.env.REACT_APP_API_PROXY + '/congratulations/api/texts', {
            method: "GET",
        }).then((response) => response.json()).then((response) => {
            setDictionary(response);
        });
    }

    if(window.matchMedia("(min-width: 1024px)").matches){
        return(
            <div className="sc__constructor desk-only">
                <div className="poster__leaf-1">
                    <img src={require("../assets/img/leaf-5.svg").default}/>
                </div>
                <div className="const__svg-1">
                    <svg width="702" height="177" viewBox="0 0 702 177" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <mask id="const-line-1">
                                <path id="const-line-1-path"
                                      d="M878.718 -142.725C991.156 53.7715 634.784 238.705 417.188 155.904C260.117 96.1349 235.361 -17.104 359.078 -39.0871C484.623 -61.3949 479.033 88.7541 359.395 107.943C200.039 133.503 -7.17219 -207.935 1.46997 -305.723"
                                      fill="none"
                                      stroke="white"
                                      strokeWidth="1.5"
                                />
                            </mask>
                        </defs>
                        <path mask="url(#const-line-1)" d="M878.718 -142.725C991.156 53.7715 634.784 238.705 417.188 155.904C260.117 96.1349 235.361 -17.104 359.078 -39.0871C484.623 -61.3949 479.033 88.7541 359.395 107.943C200.039 133.503 -7.17219 -207.935 1.46997 -305.723" stroke="#E3B759" strokeWidth="1.5" strokeDasharray="4 4"/>
                    </svg>
                    <div className="const__svg-icon-1">
                        <img src={require("../assets/img/lk-icon-2.svg").default}/>
                    </div>
                    <div className="const__svg-icon-2">
                        <img src={require("../assets/img/lk-icon-4.svg").default}/>
                    </div>
                </div>
                <div className="lk__svg-1">
                    <svg width="119" height="560" viewBox="0 0 119 560" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <mask id="lk-line-1">
                                <path id="lk-line-1-path"
                                      d="M-3.85892 1.46766C184.296 28.6878 135.903 363.814 -26.4212 466.329C-143.594 540.329 -250.291 496.108 -187.052 403.513C-127.135 315.783 -41.8056 389.882 -87.1517 480.008C-147.553 600.056 -480.269 560.683 -542.393 503.879"
                                      fill="none"
                                      stroke="white"
                                      strokeWidth="1.5"
                                />
                            </mask>
                        </defs>
                        <path mask="url(#lk-line-1)" d="M-3.85892 1.46766C184.296 28.6878 135.903 363.814 -26.4212 466.329C-143.594 540.329 -250.291 496.108 -187.052 403.513C-127.135 315.783 -41.8056 389.882 -87.1517 480.008C-147.553 600.056 -480.269 560.683 -542.393 503.879" stroke="#E3B759" strokeWidth="1.5" strokeDasharray="4 4"/>
                    </svg>

                    <div className="lk__svg-icon-1">
                        <img src={require("../assets/img/lk-icon-1.svg").default}/>
                    </div>
                </div>
                <div className="lk__svg-3">
                    <svg width="81" height="102" viewBox="0 0 81 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <mask id="lk-line-3">
                                <path id="lk-line-3-path"
                                      d="M0.674467 77.0942C12.4638 84.543 45.6922 91.4591 84.2913 59.5328C132.54 19.625 57.4072 -27.2785 35.0673 23.6421C17.1953 64.3787 73.0595 92.1886 103.226 101.002"
                                      fill="none"
                                      stroke="white"
                                      strokeWidth="1.5"
                                />
                            </mask>
                        </defs>
                        <path mask="url(#lk-line-3)" d="M0.674467 77.0942C12.4638 84.543 45.6922 91.4591 84.2913 59.5328C132.54 19.625 57.4072 -27.2785 35.0673 23.6421C17.1953 64.3787 73.0595 92.1886 103.226 101.002" stroke="#E3B759" strokeWidth="1.5" strokeDasharray="4 4"/>
                    </svg>
                    <div className="lk__svg-icon-3">
                        <img src={require("../assets/img/lk-icon-3.svg").default}/>
                    </div>
                </div>

                <div className="const__svg-icon-3">
                    <img src={require("../assets/img/leaf-2.svg").default}/>
                </div>
                <div className="poster__candy-1">
                    <img src={require("../assets/img/candy-2.png")}/>
                </div>
                <div className="poster__candy-2">
                    <img src={require("../assets/img/candy-1.png")}/>
                </div>
                <div className="container d-flex justify-content-center align-items-center">
                    <div className="sc__promo-ct">
                        <Link to="/" className="logo">
                            <img src={require("../assets/img/logo.png")}/>
                        </Link>
                        <div className="sc__header">
                            <div className="sc__title gradient-text text-center">
                                <span>Сайт доступен</span> <br/>
                                для просмотра со смартфона
                            </div>
                            <div className="sc__descr text-center">
                                Для просмотра этой страницы откройте ее&nbsp;на&nbsp;мобильном устройстве
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="cnst__box">
            <div className="cnst__rotate-box d-flex align-items-center justify-content-center fadeIn animated">
                <div className="cnst__rotate-decor-1"/>
                <div className="cnst__rotate-decor-2"/>
                <div className="cnst__rotate-decor-3"/>
                <div className="cnst__rotate-ct">
                    <div className="cnst__rotate-img">
                        <img src={require("../assets/img/rotate-img.svg").default}/>
                    </div>
                    <div className="cnst__rotate-title text-center gradient-text">
                        Поверните устройство <br/>
                        вертикально
                    </div>
                </div>
            </div>
            <div id="cnst-bl"
                 className={'cnst__bl d-flex flex-wrap justify-content-between' + (result && !preview && !processing ? ' ' : ' align-content-between')}
                 data-step={step}
                 data-fixed={fixed}
                 data-mode={mode}
                 data-processing={processing}
                 data-result={result}>

                {!processing && !result && (
                    <>
                       {(step === 0 || step === 1) && mode !== 3 && (
                            <>
                                <div className="cnst-decor-1 fadeIn animated"/>
                                <div className="cnst-decor-2 fadeIn animated"/>
                                <div className="cnst-decor-3 fadeIn animated"/>
                            </>
                       )}

                        {step === 2 && mode !== 3 && (
                            <>
                                <div className="cnst-decor-2 fadeIn animated"/>
                                <div className="cnst-decor-3 fadeIn animated"/>
                                <div className="cnst-decor-4 fadeIn animated"/>
                            </>
                        )}

                        {!(mode === 2 && play) && mode !== 3 && (
                            <div className="cnst__bl-ct">
                                <a onClick={()=>{
                                    reset();
                                }} className="cnst__logo">
                                    <img src={require("../assets/img/logo-small.svg").default}/>
                                </a>
                            </div>
                        )}

                        {step === 0 && (
                            <>
                                <div className="cnst__bl-ct fadeIn animated">
                                    <div className="cnst__bl-title cnst__bl-title--1 gradient-text text-center">
                                        ТОМУ, КТО <br/>
                                        НАУЧИЛ <br/>
                                        ВАЖНОМУ
                                    </div>
                                </div>
                                <div className="cnst__bl-ct fadeIn animated start-actions">
                                    <div onClick={()=>{
                                        setMode(1);
                                        setStep(1);
                                        setFormCode('');
                                    }}  className="btn-ct btn--full btn-ct--large d-flex align-items-center justify-content-center">
                                        <span className="gradient-text">Я поздравляю</span>
                                    </div>
                                    <div onClick={()=>{
                                        setMode(2);
                                        setStep(1);
                                    }} className="btn-ct btn--full btn-ct--large d-flex align-items-center justify-content-center">
                                        <span className="gradient-text">Меня поздравляют</span>
                                    </div>
                                </div>
                                <div className="cnst__bl-ct fadeIn animated z-index--3">

                                    <div className="cnst__link text-center">
                                        <a href="/rules.pdf" target="_blank">
                                            Правила акции
                                        </a>
                                    </div>
                                    {/*<div className="cnst__link text-center">
                                        <a href="/user-agreement.pdf" target="_blank">
                                            Пользовательское соглашение
                                        </a>
                                    </div>*/}
                                </div>
                            </>
                        )}

                        {mode === 1 && step === 1 && (
                            <>
                                <div className="cnst__bl-ct fadeIn animated choose-box">
                                    <div className="cnst__bl-ct-it">
                                        <div className="cnst__bl-title gradient-text text-center">
                                            КОГО <br/>
                                            ВЫ ХОТИТЕ <br/>
                                            ПОЗДРАВИТЬ?
                                        </div>
                                        <div className="cnst__bl-descr text-center gradient-text">
                                            Начните писать в поле ввода <br/>
                                            и выберите вариант из списка
                                        </div>
                                    </div>
                                    <div className="cnst__bl-ct-it">
                                        <div className={'cnst__select' + ((name && name.length > 1 && data.name_id === null) ? ' opened' : '')}>
                                            <div className="cnst__select-input">
                                                <input
                                                    type="text"
                                                    placeholder="НАЧНИТЕ ВВОДИТЬ ИМЯ"
                                                    value={name}
                                                    onChange={(e)=>{
                                                       setName(e.target.value.toUpperCase());
                                                        setData({
                                                            ...data,
                                                            name: null,
                                                            name_id: null
                                                        });
                                                    }}
                                                />
                                                <div className="cnst__select-toggle"/>
                                            </div>
                                            {dictionary.firstNames.filter((it) => it.full.includes(name.toUpperCase())).length > 0 && (
                                                <div className="cnst__select-dropdown fadeIn animated">
                                                    {dictionary.firstNames.filter((it) => it.full.includes(name.toUpperCase())).map((nameIt, i) => (
                                                        <div onClick={()=>{
                                                            setName(nameIt.full);
                                                            setData({
                                                                ...data,
                                                                name: nameIt.full,
                                                                name_id: nameIt.id,
                                                                name_key: nameIt.key
                                                            });
                                                        }} className="cnst__select-dropdown-it">
                                                            {nameIt.full}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="cnst__bl-ct-it">
                                        <div className={'cnst__select' + ((fname && fname.length > 1 && data.fname_id === null) ? ' opened' : '')}>
                                            <div className="cnst__select-input">
                                                <input
                                                    type="text"
                                                    placeholder="ОТЧЕСТВО (ПО ЖЕЛАНИЮ)"
                                                    value={fname}
                                                    onChange={(e)=>{
                                                        setFname(e.target.value.toUpperCase());
                                                        setData({
                                                            ...data,
                                                            fname: null,
                                                            fname_id: null,
                                                            fname_key: null
                                                        });
                                                    }}
                                                />
                                                <div className="cnst__select-toggle"/>
                                            </div>
                                            {dictionary.lastNames.filter((it) => it.full.includes(fname.toUpperCase())).length > 0 && (
                                                <div className="cnst__select-dropdown fadeIn animated">
                                                    {dictionary.lastNames.filter((it) => it.full.includes(fname.toUpperCase())).map((fnameIt, i) => (
                                                        <div onClick={()=>{
                                                            setFname(fnameIt.full);
                                                            setData({
                                                                ...data,
                                                                fname: fnameIt.full,
                                                                fname_id: fnameIt.id,
                                                                fname_key: fnameIt.key
                                                            });
                                                        }} className="cnst__select-dropdown-it">
                                                            {fnameIt.full}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="cnst__bl-ct fadeIn animated bottom-actions">
                                    <div className="actions__it d-flex justify-content-center">
                                        <button disabled={!data.name} type="button" onClick={()=>{
                                            setStep(2);
                                        }}  className="btn-ct btn-ct--medium d-flex align-items-center justify-content-center">
                                            Далее
                                        </button>
                                    </div>
                                    <div className="actions__it d-flex justify-content-center">
                                        <div onClick={()=>{
                                            setStep(2);
                                        }} className="btn__link gradient-text">
                                            <span>ПРОПУСТИТЬ</span>
                                        </div>
                                    </div>
                                    <div className="actions__it d-flex justify-content-center">
                                        <div onClick={()=>{
                                            reset();
                                        }} className="btn__link btn__link--back d-flex align-items-center justify-content-center">
                                            <i className="icon__back"/>
                                            <span className="gradient-text">назад</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                       {mode === 1 && step === 2 && (
                           <>
                               <div className="cnst__bl-ct fadeIn animated choose-box">
                                   <div className="cnst__bl-ct-it">
                                       <div className="cnst__bl-title gradient-text text-center">
                                           ЧТО <br/>
                                           ВЫ ХОТИТЕ <br/>
                                           ПООБЕЩАТЬ?
                                       </div>
                                       <div className="cnst__bl-descr text-center gradient-text">
                                           Выберите вариант из списка
                                       </div>
                                   </div>
                                   <div className="cnst__bl-ct-it">
                                       <div className={'cnst__options' + (data.text_id ? ' cnst__options--chosen' : '')}>
                                           {dictionary.texts.map((it, i) => (
                                               <div onClick={()=>{
                                                   setData({
                                                       ...data,
                                                       text: it.short,
                                                       text_id: it.id,
                                                       text_key: it.key
                                                   });

                                                   if(window.dataLayer) {
                                                       if(it.id == 388) {
                                                           window.dataLayer.push({
                                                               'event': 'better_time_management'
                                                           });
                                                       }

                                                       if(it.id == 389) {
                                                           window.dataLayer.push({
                                                               'event': 'listen_carefully'
                                                           });
                                                       }

                                                       if(it.id == 390) {
                                                           window.dataLayer.push({
                                                               'event': 'wise_advice'
                                                           });
                                                       }

                                                       if(it.id == 391) {
                                                           window.dataLayer.push({
                                                               'event': 'celebrate_success'
                                                           });
                                                       }

                                                       if(it.id == 392) {
                                                           window.dataLayer.push({
                                                               'event': 'strive_to_goals'
                                                           });
                                                       }

                                                       if(it.id == 393) {
                                                           window.dataLayer.push({
                                                               'event': 'self_development'
                                                           });
                                                       }

                                                       if(it.id == 394) {
                                                           window.dataLayer.push({
                                                               'event': 'take_responsibility'
                                                           });
                                                       }

                                                       if(it.id == 395) {
                                                           window.dataLayer.push({
                                                               'event': 'dare_to_dream'
                                                           });
                                                       }

                                                       if(it.id == 396) {
                                                           window.dataLayer.push({
                                                               'event': 'be_more_decisive'
                                                           });
                                                       }

                                                       if(it.id == 397) {
                                                           window.dataLayer.push({
                                                               'event': 'listen_to_desires'
                                                           });
                                                       }
                                                   }
                                               }} className={'cnst__option d-flex align-items-center' + (data.text_id === it.id ? ' active' : '')}>
                                                   <div className="cnst__option-decor"/>
                                                   <div className={'cnst__option-title' + ((data.text_id === it.id || !data.text_id) ? ' gradient-text' : '')}>
                                                       {it.short}
                                                   </div>
                                               </div>
                                           ))}
                                       </div>
                                   </div>
                               </div>
                               <div className="cnst__bl-ct fadeIn animated bottom-actions fixed-actions">
                                   <div className="fixed-actions__wrap">
                                       <div className="actions__it d-flex justify-content-center">
                                           <button disabled={!data.text_id} type="button" onClick={()=>{
                                               completeCongratulations();
                                               setResult(true);
                                           }}  className="btn-ct btn-ct--medium d-flex align-items-center justify-content-center">
                                               Далее
                                           </button>
                                       </div>
                                       <div className="actions__it d-flex justify-content-center">
                                           <div onClick={()=>{
                                               setStep(1);
                                           }} className="btn__link btn__link--back d-flex align-items-center justify-content-center">
                                               <i className="icon__back"/>
                                               <span className="gradient-text">назад</span>
                                           </div>
                                       </div>
                                   </div>

                               </div>
                           </>
                       )}


                        {mode === 2 && !play && (
                            <>
                                <div className="cnst__bl-ct fadeIn animated">
                                    <div className="cnst__bl-title result__title result__title--indent gradient-text text-center">
                                        ВВЕДИТЕ <br/>
                                        СЕКРЕТНЫЙ КОД, <br/>
                                        НАПИСАННЫЙ НА ОТКРЫТКЕ, <br/>
                                        ЧТОБЫ ПОСМОТРЕТЬ <br/>
                                        ВАШЕ ПОЗДРАВЛЕНИЕ
                                    </div>
                                </div>
                                <div className="cnst__bl-ct fadeIn animated">
                                    <form autoComplete="off" className={'code__form code__form--main d-flex' + (isSubmitting ? ' form--loading' : '')}>
                                        <div className={'code__field' + (resultError ? ' code__field--error' : '')}>
                                            <input
                                                type="password"
                                                autoComplete="off"
                                                value={formCode}
                                                onChange={(e)=>{
                                                   setFormCode(e.target.value);
                                                   setResultError('');
                                                }}
                                                placeholder="Код"/>
                                        </div>
                                    </form>
                                    {resultError && (
                                        <p className="error__message">
                                            {resultError}
                                        </p>
                                    )}
                                </div>
                                <div className="cnst__bl-ct fadeIn animated bottom-actions bottom-actions--result">
                                    <div className="actions__it">
                                        <button type="button" disabled={!formCode || isSubmitting} onClick={()=>{
                                            getCongratulation();
                                        }}  className="btn-ct btn--full btn-ct--large d-flex align-items-center justify-content-center">
                                            Посмотреть
                                        </button>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div onClick={()=>{
                                            setMode(3);
                                            if(window.dataLayer) {
                                                window.dataLayer.push({
                                                    'event': 'viewed_general_congratulation'
                                                });
                                            }
                                        }} className="btn__link">
                                            <span>У меня нет</span><br/>
                                            <span>секретного</span> <br/>
                                            <span>кода</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {mode === 2 && play && videoLink && (
                            <div id="preview-player" className="preview__player preview__player--main fadeIn animated">
                                <ReactPlayer
                                    url={videoLink}
                                    playing={playing}
                                    playsinline
                                    onEnded={()=>{
                                        //setPreview(false);
                                        //setPlay(false);
                                        setEnded(true);
                                    }}

                                    onProgress={(res) => {
                                        if(res.playedSeconds > 18) {
                                            setEnded(true);
                                        }

                                        if(res.playedSeconds > 3 && !paused) {
                                            setPlaying(false);
                                            setPressBtn(true);
                                            setPaused(true);
                                        }
                                    }}
                                />

                                {pressBtn && (
                                    <>
                                        <div onClick={()=>{
                                            setPlaying(true);
                                            setPressBtn(false);
                                        }} className="btn__circle fadeIn animated"/>
                                        <div className="video__preview-close d-flex justify-content-center">
                                            <div onClick={()=>{
                                                reset();
                                            }} className="d-flex align-items-center btn__back">
                                                <i className="icon__back"/>
                                                <span className="gradient-text">Назад</span>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {ended && (
                                    <div className="video__actions fadeIn animated">
                                        <div className="actions__it">
                                            <a target="_blank"
                                               onClick={()=>{
                                                   if(window.dataLayer) {
                                                       window.dataLayer.push({
                                                           'event': 'save_video'
                                                       });
                                                   }
                                               }}
                                               download
                                               href={videoLink}
                                               className="btn-ct btn--full btn-ct--gradient d-flex align-items-center justify-content-center">
                                                Сохранить поздравление
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {mode === 3 && (
                            <>
                                {/*<div className="node__code-decor-1">
                                    <img src={require("../assets/img/no-code-img-2.png")}/>
                                </div>
                                <div className="node__code-decor-2">
                                    <img src={require("../assets/img/no-code-img-3.png")}/>
                                </div>
                                <div className="node__code-box">
                                    <div className="node__code-box-bl">
                                        <div className="node__code-logo">
                                            <img src={require("../assets/img/logo-sm-2.png")}/>
                                        </div>
                                        <div className="node__code-title text-uppercase text-center gradient-text">
                                            Поздравляю <br/>
                                            с праздником!
                                        </div>
                                        <div className="node__code-descr">
                                            <p>
                                                Вы научили меня важному, <br/>
                                                и я обещаю увереннее идти <br/>
                                                к своим целям!
                                            </p>
                                            <p>
                                                Вы помогаете становиться <br/>
                                                лучше с каждым днём!
                                            </p>
                                        </div>
                                        <div className="node__code-img">
                                            <img src={require('../assets/img/no-code-img-1.png')}/>
                                        </div>
                                    </div>
                                </div>*/}

                                <div id="preview-player" className="preview__player preview__player--main fadeIn animated">
                                    <ReactPlayer
                                        url={'https://generator.mnbvcx.ru:8443/outdata/0.mp4'}
                                        playing={playing}
                                        playsinline
                                        onEnded={()=>{
                                            setEnded(true);
                                        }}

                                        onProgress={(res) => {
                                            if(res.playedSeconds > 18) {
                                                setEnded(true);
                                            }
                                        }}
                                    />

                                    {ended && (
                                        <div className="video__actions fadeIn animated">
                                            <div className="actions__it">
                                                <a target="_blank"
                                                   onClick={()=>{
                                                       if(window.dataLayer) {
                                                           window.dataLayer.push({
                                                               'event': 'save_video'
                                                           });
                                                       }
                                                   }}
                                                   download
                                                   href={'https://generator.mnbvcx.ru:8443/outdata/0.mp4'}
                                                   className="btn-ct btn--full btn-ct--gradient d-flex align-items-center justify-content-center">
                                                    Сохранить поздравление
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                )}

                {processing && (
                    <>
                        <div className="cnst-decor-1 fadeIn animated"/>
                        <div className="cnst-decor-2 fadeIn animated"/>
                        <div className="cnst-decor-3 fadeIn animated"/>
                        <div className="cnst__bl-ct fadeIn animated">
                            <a onClick={()=>{
                                reset();
                            }} className="cnst__logo">
                                <img src={require("../assets/img/logo-small.svg").default}/>
                            </a>
                        </div>
                        <div className="cnst__bl-ct fadeIn animated">
                            <div className="loading__box d-flex align-items-center justify-content-center">
                                <div className="loader--inline">
                                    <img src={require('../assets/img/loader-wh.svg').default}/>
                                </div>
                            </div>
                        </div>
                        <div className="cnst__bl-ct fadeIn animated">
                            <div className="cnst__bl-title cnst__bl-title--small gradient-text text-center text-uppercase">
                                Ваше поздравление <br/>
                                генерируется
                            </div>
                        </div>
                    </>
                )}

                {preview && (
                    <div className="cnst__bg--semi"/>
                )}

                {result && !processing && (
                    <>
                        <div className="cnst-decor-1 fadeIn animated"/>
                        <div className="cnst-decor-2 fadeIn animated"/>
                        <div className="cnst-decor-3 fadeIn animated"/>
                        <div className={'cnst__bl-ct' + (!preview ? ' result-logo-wrap' : '')}>
                            <a onClick={()=>{
                                reset();
                            }} className="cnst__logo">
                                <img src={require("../assets/img/logo-small.svg").default}/>
                            </a>
                        </div>

                        {!preview && (
                            <>
                                <div className="cnst__bl-ct fadeIn animated result-bl">
                                    <div className="result__header d-flex align-items-center">
                                        <div className="result__title gradient-text">
                                            Сохраните этот <br/>
                                            секретный код <br/>
                                            и впишите его <br/>
                                            в открытку
                                        </div>
                                    </div>
                                    <div className="code__form d-flex">
                                        <div className="code__field">
                                            <input
                                                type="text"
                                                value={code}
                                                readOnly={true}/>
                                        </div>
                                        <CopyToClipboard text={code}
                                                         onCopy={() => {
                                                             setCopied(true);
                                                             setTimeout(() => {
                                                                 setCopied(false);
                                                             }, 1200);
                                                         }}>
                                            <div className="btn-ct btn--copy btn-ct--small d-flex align-items-center justify-content-center">
                                                {copied ? 'Скопировано' : 'Сохранить'}
                                            </div>
                                        </CopyToClipboard>
                                    </div>
                                    <div className="result__descr">
                                        <p>
                                            Для просмотра поздравления получателю необходимо:
                                        </p>
                                        <ol>
                                            <li>
                                                Отсканировать QR-код с открытки
                                            </li>
                                            <li>
                                                Перейти в раздел «Меня <br/>
                                                поздравляют»
                                            </li>
                                            <li>
                                                Ввести секретный код, <br/>
                                                вписанный вами в открытку
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="cnst__bl-ct fadeIn animated bottom-actions bottom-actions--result">
                                    <div className="actions__it">
                                        <a target="_blank"
                                           onClick={()=>{
                                                if(window.dataLayer) {
                                                    window.dataLayer.push({
                                                        'event': 'save_congratulation'
                                                    });
                                                }
                                            }}
                                            download
                                            href={videoLink}
                                           className="btn-ct btn--full btn-ct--large d-flex align-items-center justify-content-center"
                                        >
                                            Скачать поздравление
                                        </a>
                                    </div>
                                    <div className="actions__it">
                                        <a onClick={()=>{
                                            if(window.dataLayer) {
                                                window.dataLayer.push({
                                                    'event': 'register_check_screens',
                                                    'screen': 'congrat_made'
                                                });
                                            }
                                        }} href="https://promo.korkunov.ru/" className="btn-ct btn--full btn-ct--medium btn-ct--height d-flex align-items-center justify-content-center">
                                            <div>
                                                ЗАРЕГИСТРИРОВАТЬ <br/>
                                                ЧЕК И УЧАСТВОВАТЬ <br/>
                                                В РОЗЫГРЫШЕ ПРИЗОВ
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </>
                        )}

                        {preview && (
                            <>
                                <div className="cnst__bl-ct fadeIn animated">
                                    <div className="preview__box d-flex align-items-center justify-content-center">
                                        <div onClick={()=>{
                                            setPlay(true);
                                            if(window.dataLayer) {
                                                window.dataLayer.push({
                                                    'event': 'view_congratulation'
                                                });
                                            }
                                        }} className="preview__box-ct">
                                            <div className="preview__box-icon">
                                                <img src={require("../assets/img/play-icon.svg").default}/>
                                            </div>
                                            <div className="preview__box-title gradient-text text-center text-uppercase">
                                                предпросмотр
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cnst__bl-ct z-index--3 fadeIn animated">
                                    <div className="actions__it d-flex justify-content-center">
                                        <button type="button" onClick={()=>{
                                            setPreview(false);
                                        }}  className="btn-ct btn-ct--medium d-flex align-items-center justify-content-center">
                                            Далее
                                        </button>
                                    </div>
                                    <div className="cnst__bl-ct-it d-flex justify-content-center align-items-center action-padding">
                                        <div onClick={()=>{
                                            reset();
                                        }} className="d-flex align-items-center btn__back">
                                            <i className="icon__back"/>
                                            <span className="gradient-text">Назад</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {play && (
                            <div className="video__preview-wrap">
                                <div className="preview__player fadeIn animated">
                                    <ReactPlayer
                                        url={videoLink}
                                        playing={playing}
                                        playsinline
                                        onEnded={()=>{
                                            setPreview(false);
                                            setPlay(false);
                                        }}

                                        onProgress={(res) => {
                                            /*if(res.playedSeconds > 15) {
                                                setEnded(true);
                                            }*/

                                            if(res.playedSeconds > 3 && !paused) {
                                                setPlaying(false);
                                                setPressBtn(true);
                                                setPaused(true);
                                            }
                                        }}
                                    />
                                </div>

                                {pressBtn && (
                                    <>
                                        <div onClick={()=>{
                                            setPlaying(true);
                                            setPressBtn(false);
                                        }} className="btn__circle fadeIn animated"/>
                                        <div className="video__preview-close d-flex justify-content-center">
                                            <div onClick={()=>{
                                                reset();
                                            }} className="d-flex align-items-center btn__back">
                                                <i className="icon__back"/>
                                                <span className="gradient-text">Назад</span>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default Constructor;
