import parse from 'html-react-parser';

function Info({title = '', subtitle = '', text = '', icon = false, regBtn = false, close}){
    return (
        <>
            <div className="modal__ct">
                {icon && (
                    <div className="modal__ct-box">
                        <i className="icon__success" aria-hidden={true}/>
                    </div>
                )}
                {title && (
                    <div className="modal__ct-box">
                        <div className="modal__title">
                            {parse(title)}
                        </div>
                    </div>
                )}
                {text && (
                    <div className="modal__ct-box">
                        <div className="modal__subtitle">
                            {parse(text)}
                        </div>
                    </div>
                )}
                {regBtn && (
                    <div className="modal__ct-box">
                        <div className="d-flex justify-content-center modal__bottom-btn">
                            <div onClick={()=>{
                                close();
                                if(document.querySelectorAll('#apm-scan-qr')) {
                                    document.querySelectorAll('#apm-scan-qr')[0].click();
                                }
                            }} className="btn-ct btn-ct--medium">
                                <span>зарегистрировать чек</span>
                            </div>
                        </div>
                    </div>
                )}
                <div className="modal__ct-box modal__action-close d-flex justify-content-center">
                    <div onClick={()=>{
                        close()
                    }} className="btn-ct d-flex align-items-center justify-content-center">
                        <span>Хорошо</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Info;
