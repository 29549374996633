import { Link, useLocation, useParams, useMatch  } from 'react-router-dom'
import React, {useEffect, useState, useCallback} from "react";
import {useModal} from "../context";
import Feedback from "./modals/Feedback";
import Info from "./modals/Info";

function numberWithSpaces(value, pattern) {
    let i = 0,
        phone = value.toString();
    return pattern.replace(/#/g, _ => phone[i++]);
}
function Header() {
    const [nav, setNav] = useState(false);
    const [fixed, setFixed] = useState(false);
    const location = useLocation();
    const path = location.pathname;

    const { show, hide} = useModal();
    const [inner, setInner] = useState(false);

    useEffect(() => {
        setInner(location.pathname != '/');
        window.scroll(0, 0);
        if(location.pathname != '/'){
            document.getElementById('root').removeAttribute("style");
            document.getElementById('wrapper').removeAttribute("style");
        }

        /*if(location.pathname === '/constructor'){
            document.body.classList.add('page-constructor');
        }else{
            document.body.classList.remove('page-constructor');
        }*/

        setNav(false);
        document.body.classList.remove('overflow');
    }, [location.pathname]);

    useEffect(() => {
        const handleScroll = event => {
            if(window.scrollY > 5){
                setFixed(true);
            }else{
                setFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const goToAnchor = (anchor) => {
        const el = document.getElementById(anchor);
        if(el){
            el.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        }
        setNav(false);
        document.body.classList.remove('overflow');
    }

    return (
       <>
           {location.pathname != '/end' && (
               <div id="header"
                    className={'header fadeIn animated header--loaded' +
                        (inner ? ' header--inner' : '') +
                        (nav ? ' active' : '') +
                        (fixed ? ' header--fixed' : '') +
                        (nav ? ' header--open' : '')
                    }>
                   <div className="container d-flex align-items-center justify-content-between">
                       <div className="header__left d-flex align-items-center">
                           {!inner && (
                               <div onClick={()=>{
                                   goToAnchor('promo');
                               }} className="logo">
                                   <img src={require("../assets/img/logo.png")}/>
                               </div>
                           )}
                           {inner && (
                               <Link to="/" className="logo">
                                   <img src={require("../assets/img/logo.png")}/>
                               </Link>
                           )}
                       </div>
                       <div className="nav__box fadeIn animated">
                           <div className="nav__bl">
                               <div className="nav__menu">
                                   <ul>
                                       <li>
                                           {inner && (
                                               <Link to="/#steps">Создать поздравление</Link>
                                           )}
                                           {!inner && (
                                               <a onClick={()=>{
                                                   goToAnchor('steps');
                                               }}>Создать поздравление</a>
                                           )}
                                       </li>
                                       <li>
                                           {inner && (
                                               <Link to="/#prizes">Выиграть призы</Link>
                                           )}
                                           {!inner && (
                                               <a onClick={()=>{
                                                   goToAnchor('prizes');
                                               }}>Выиграть призы</a>
                                           )}
                                       </li>
                                       <li>
                                           {inner && (
                                               <Link to="/#reg">Зарегистрировать чек</Link>
                                           )}
                                           {!inner && (
                                               <a onClick={()=>{
                                                   goToAnchor('reg');
                                               }}>Зарегистрировать чек</a>
                                           )}
                                       </li>
                                       <li>
                                           <a href="/archive" target="_blank">Архив</a>
                                       </li>
                                       <li>
                                           {inner && (
                                               <Link to="/#faq">Вопросы и ответы</Link>
                                           )}
                                           {!inner && (
                                               <a onClick={()=>{
                                                   goToAnchor('faq');
                                               }}>Вопросы и ответы</a>
                                           )}
                                       </li>
                                   </ul>
                               </div>
                           </div>
                       </div>
                       <div onClick={()=>{
                           setNav(!nav);
                           if(document.body.classList.contains('overflow')) {
                               document.body.classList.remove('overflow');
                           }else {
                               document.body.classList.add('overflow');
                           }
                       }} className="nav__toggle d-flex align-items-center justify-content-center mb-only">
                           {!nav && (
                               <i className="icon__bars fadeIn animated">
                                   <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M23.4062 2.21094H1.46875" stroke="#FFF7F4" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                                       <path d="M23.4062 9.52075H8.78125" stroke="#FFF7F4" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                                       <path d="M23.4062 16.8306H6.34375" stroke="#FFF7F4" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                                   </svg>
                               </i>
                           )}

                           {nav && (
                               <i className="icon__close fadeIn animated">
                                   <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M1.51465 1.25732L22.9999 22.7426" stroke="white" strokeWidth="2.5" strokeLinecap="round"/>
                                       <path d="M23 1.25732L1.51473 22.7426" stroke="white" strokeWidth="2.5" strokeLinecap="round"/>
                                   </svg>
                               </i>
                           )}
                       </div>
                   </div>
               </div>
           )}
       </>
    );
}

export default Header;
