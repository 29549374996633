import React, {useState, useEffect} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useModal} from "../../context";
import Info from "./Info";
import axiosInstance from "../../helpers/axios";

const topics = [
    {
        title: 'Регистрация в акции',
        id: 1
    },
    {
        title: 'Получение призов',
        id: 2
    },
    {
        title: 'Технические вопросы',
        id: 3
    },
    {
        title: 'Вопрос о продукте',
        id: 6
    },
    {
        title: 'Другое',
        id: 4
    }
];

function Feedback({product = false}){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [theme, setTheme] = useState('');
    const [themeTitle, setThemeTitle] = useState('');
    const [attachFile, setAttachFile] = useState('');
    const [isThemeDropdownOpen, setIsThemeDropdownOpen] = useState(false);

    const { show, hide} = useModal();

    const schema = yup.object({
        name: yup.string().required('Некорректное Имя'),
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        text: yup.string().min(6, 'Мин. 5 символов').required('Обязательно к заполнению'),
        theme_id: yup.string().required('Выберите тему обращения'),
        agree: yup.boolean().oneOf([true], 'Необходимо согласие'),
        agree2: yup.boolean().oneOf([true], 'Необходимо согласие')
    }).required();

    const { register,reset, setValue, clearErrors, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(()=>{
        //setValue('agree', true);
        if(product){
            setValue('theme_id', 6);
            setThemeTitle('Вопрос о продукте');
            setTheme(6);
        }
    }, []);

    const watchEmail = watch("email");
    const watchName = watch("name");
    const watchMess = watch("text");
    const watchTheme = watch("theme_id");

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        const fileInput = document.querySelector('#file') ;
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("text", data.text);
        formData.append("theme_id", data.theme_id);
        formData.append("agree", 'yes');

        if(fileInput.files[0] !== undefined){
            formData.append('file', fileInput.files[0]);
        }

        try {
            const response = await axiosInstance.post('/feedbacks/api/send', formData);
            if(response.data){
                reset();
                setAttachFile('');
                setThemeTitle('');
                setTheme('');
                show(<Info
                    icon={true}
                    title="спасибо <br/> за обращение"
                    text="ваш вопрос будет обработан в&nbsp;течение 24&nbsp;часов. Ответ будет отправлен на&nbsp;указанный электронный адрес"
                    close={hide}/>, "modal--info");
            }
            setIsSubmitting(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__header">
                        <div className="modal__title">
                            задать вопрос
                        </div>
                    </div>
                </div>
                <div className="modal__ct-box">
                    <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="row">
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.name ? ' has-error' : '') + ((watchName && watchName != '') ? ' has-value' : '')}>
                                        <input
                                            type="text"
                                            id="name"
                                            {...register("name")}
                                        />
                                        <label htmlFor="name" className="form__label">
                                            Имя
                                        </label>
                                    </div>
                                    {errors.name && (
                                        <p className="error__message">
                                            {errors.name?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.email ? ' has-error' : '') + ((watchEmail && watchEmail != '') ? ' has-value' : '')}>
                                        <input
                                            autoComplete="username"
                                            type="text"
                                            id="email"
                                            {...register("email")}
                                        />
                                        <label for="email" className="form__label">
                                            Электронная почта
                                        </label>
                                    </div>
                                    {errors.email && (
                                        <p className="error__message">
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__select' + (isThemeDropdownOpen ? ' active' : '') + (theme != '' ? ' has-value' : '') + (errors.theme_id ? ' has-error' : '')}>
                                        <div onClick={()=>{
                                            setIsThemeDropdownOpen(!isThemeDropdownOpen);
                                        }} className="form__select-input d-flex align-items-center justify-content-between">
                                            <div className="form__label">
                                                Тема обращения
                                            </div>
                                            <div className={'form__select-value' + (theme == '' ? ' form__select-value--placeholder' : '')}>
                                                {theme != '' ? themeTitle : ''}
                                            </div>
                                            <div className="form__select-toggle">
                                                <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.2695 1L11.3596 10L1.44968 0.999999" stroke="#AF8B56" strokeWidth="2"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="form__select-dropdown">
                                            <div className="form__dropdown-list">
                                                {topics.map((it, i) => (
                                                    <div onClick={()=>{
                                                        setIsThemeDropdownOpen(false);
                                                        setThemeTitle(it.title);
                                                        setTheme(it.id);
                                                        setValue('theme_id', it.id);
                                                        clearErrors('theme_id');
                                                    }} className={'form__dropdown-it' + (it.id == theme ? ' active' : '')}>
                                                        {it.title}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <input type="hidden" {...register("theme_id")}/>

                                    {errors.theme_id && (
                                        <p className="error__message">
                                            {errors.theme_id?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.text ? ' has-error' : '') + ((watchMess && watchMess != '') ? ' has-value' : '')}>
                                        <textarea
                                            id="text"
                                            {...register("text")}
                                        />
                                        <label for="text" className="form__label">
                                            Ваш вопрос
                                        </label>
                                    </div>
                                    {errors.text && (
                                        <p className="error__message">
                                            {errors.text?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it--attach d-flex align-items-center">
                                    <label className="d-flex align-items-center form__attach-btn">
                                        <input type="file" id="file" {...register("attachment")} accept="image/*" onChange={(event)=>{
                                            const fileInput = document.querySelector('#file') ;
                                            const files = event.target.files;
                                            const fileName = files[0].name;
                                            setAttachFile(fileName);
                                        }}/>
                                        <div className={'file__attach-btn-icon d-flex align-items-center justify-content-center' + (attachFile != '' ? ' has-image' : '')}>
                                            <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.7367 9.25305L8.60494 17.3848C7.04223 18.9475 4.51079 18.9475 2.94809 17.3848C1.38538 15.8221 1.38538 13.2906 2.94809 11.7279L11.7869 2.88909C12.2558 2.42025 12.8916 2.15685 13.5547 2.15685C14.2177 2.15685 14.8536 2.42025 15.3225 2.88909C15.7913 3.35793 16.0547 3.99381 16.0547 4.65685C16.0547 5.3199 15.7913 5.95578 15.3225 6.42462L7.89783 13.8492C7.50892 14.2382 6.87253 14.2382 6.48362 13.8492C6.09471 13.4603 6.09471 12.8239 6.48362 12.435L13.2011 5.71751L12.1405 4.65685L5.42296 11.3744C4.95412 11.8432 4.69073 12.4791 4.69073 13.1421C4.69073 13.8052 4.95412 14.4411 5.42296 14.9099C5.8918 15.3787 6.52768 15.6421 7.19073 15.6421C7.85377 15.6421 8.48965 15.3787 8.95849 14.9099L16.3831 7.48528C17.9458 5.92258 17.9458 3.39113 16.3831 1.82843C14.8204 0.265721 12.289 0.265721 10.7263 1.82843L1.88743 10.6673C-0.262179 12.8169 -0.262179 16.2958 1.88743 18.4454C4.03703 20.595 7.516 20.595 9.6656 18.4454L17.7973 10.3137L16.7367 9.25305Z" fill="#4C3127"/>
                                            </svg>
                                        </div>
                                        <span>{attachFile ? attachFile : 'Изображение'}</span>

                                        {attachFile != '' && (
                                            <div onClick={(e)=>{
                                                setValue('attachment', null);
                                                setAttachFile('');
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }} className="file__clear">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2.69235L2.69231 2.00005L19.9999 19.3077L19.3076 20L2 2.69235Z" fill="#9D0250"></path><path d="M19.3077 2L20 2.69231L2.69237 20L2.00006 19.3076L19.3077 2Z" fill="#9D0250"></path></svg>
                                            </div>
                                        )}
                                    </label>

                                </div>
                                <div className="col-12 form__it">
                                    <div className={'checkbox__wrap' + (errors.agree ? ' has-error' : '')}>
                                        <label className="align-items-start">
                                            <input
                                                type="checkbox"
                                                {...register("agree")}
                                            />
                                            <div className="checkbox__decor"/>
                                            <div className="checkbox__title text-left">
                                                Я&nbsp;ознакомился с&nbsp;<a href="https://www.mars.com/privacy-policy-russian" target="_blank">Положением о&nbsp;конфиденциальности</a>, <a href="/user-agreement.pdf" target="_blank">Пользовательским соглашением</a> и&nbsp;даю свое согласие на&nbsp;обработку персональных данных. Персональные данные, отправленные через эту форму, будут использованы только для ответа на&nbsp;конкретный вопрос, а&nbsp;также в&nbsp;целях аналитики, и&nbsp;не&nbsp;будут использоваться в&nbsp;маркетинговых целях
                                            </div>
                                        </label>
                                    </div>
                                    {errors.agree && (
                                        <p className="error__message">
                                            {errors.agree?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it--action">
                                    <div className={'checkbox__wrap' + (errors.agree2 ? ' has-error' : '')}>
                                        <label className="align-items-center">
                                            <input
                                                type="checkbox"
                                                {...register("agree2")}
                                            />
                                            <div className="checkbox__decor"/>
                                            <div className="checkbox__title text-left">
                                                Мне исполнилось 18&nbsp;лет
                                            </div>
                                        </label>
                                    </div>
                                    {errors.agree2 && (
                                        <p className="error__message">
                                            {errors.agree2?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it--action">
                                    <button type="submit" className="btn-ct btn-ct--large btn--full d-flex align-items-center justify-content-center">
                                        <span>Отправить</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Feedback;
